import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import LoadingIndicator from 'components/LoadingIndicator';
import withColorType from 'components/HOC/withColorType';

function AutoNext({ data, authenticationId, colorType }) {
  const { status } = data;
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
        data: {},
      }),
    );
  }, [authenticationId, status]);

  return <LoadingIndicator colorType={colorType} />;
}

AutoNext.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default withColorType(AutoNext);

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import usePortal from 'react-useportal';
import ResponseCarousel from 'components/ResponseCarousel';
import { unsetBack } from 'containers/App/actions';
import { getAuthenticationResponseConfRequest } from 'containers/Authentication/actions';
import { useParams } from 'react-router-dom';
import useDimensions from 'react-use-dimensions';
import { useChain, animated, useSpring, useSpringRef } from '@react-spring/web';
import {
  selectAuthenticationResponseConfLoading,
  selectAuthenticationResponseConfData,
  selectAuthenticationData,
} from 'containers/Authentication/selectors';
import LoadingIndicator from 'components/LoadingIndicator';
import usePrompt from 'components/hooks/usePrompt';

import get from 'lodash/get';
import { typography } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { selectConf, selectPoweredBy } from 'containers/App/selectors';
import { Div } from '@ebay-certilogo/design-system-web';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import withColorType from 'components/HOC/withColorType';
import history from 'utils/history';
import { useTheme } from 'styled-components';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { transparentize } from 'polished';
import ProgressBarTimer from 'components/ProgressBarTimer';
import { isNil } from 'lodash';
import useColorType from 'components/hooks/useColorType';
import { isAppeal } from './utils';
import responses from './responses';
import { ResponseWrapper, Response, ResponseCarouselWrapper, Wrapper, ButtonHome } from './styles';
import completeActionFn from './completeActionFn';

function Complete() {
  const theme = useTheme();
  const { authenticationId } = useParams();
  const responseWrapperRef = useRef();
  const [responseRef, responseDimensions] = useDimensions();
  const [responseHeight, setResponseHeight] = useState(0);
  const [showResponse, setShowResponse] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showResponseCarousel, setShowResponseCarousel] = useState(false);
  const isLoading = useSelector(selectAuthenticationResponseConfLoading);
  let data = useSelector(selectAuthenticationResponseConfData);
  const authentication = useSelector(selectAuthenticationData);
  const authenticatorConf = useSelector(selectConf);
  const colorType = useColorType(
    data?.response === 'impossible' ? 'authenticationCompleteImpossible' : 'authentication',
  );
  const { Portal } = usePortal({
    bindTo: document && document.getElementById('layout-root-portal'),
  });
  const poweredBy = useSelector(selectPoweredBy);
  const poweredByHeight = poweredBy ? 25 : 0;
  const dispatch = useDispatch();
  // eslint-disable-next-line prettier/prettier
  const logger = () => { };
  useEffect(() => {
    dispatch(unsetBack());
    dispatch(getAuthenticationResponseConfRequest({ authenticationId }));
  }, []);

  useEffect(() => {
    if (authentication?.props?.completeAction) {
      completeActionFn(authentication?.props?.completeAction, { authentication });
    }
  }, [authentication]);

  const responseTranslationHeight =
    window.innerHeight / 2 - parseInt(typography.size.mobile.xl) - 60 - 100;
  const springRef = useSpringRef();
  const springProps = useSpring({
    from: {
      position: 'relative',
      transform: `translateY(${responseTranslationHeight}px)`,
      fontSize: typography.size.mobile.xl,
    },
    to: {
      position: 'relative',
      transform: showResponseCarousel
        ? 'translateY(0px)'
        : `translateY(calc(${responseTranslationHeight}px)`,
      fontSize: typography.size.mobile.l,
    },
    ref: springRef,
    onRest: () => {
      setResponseHeight(
        responseWrapperRef.current && responseWrapperRef.current.getBoundingClientRect().height,
      );
    },
  });

  useEffect(() => {
    setResponseHeight(responseDimensions.height);
  }, [responseDimensions]);

  const carouselRef = useSpringRef();
  const carouselProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: showResponseCarousel ? 1 : 0 },
    ref: carouselRef,
  });

  useChain(
    showResponseCarousel ? [springRef, carouselRef] : [],
    showResponseCarousel ? [0, 1] : [],
  );

  usePrompt(data);
  if (isLoading || !data) return <LoadingIndicator />;

  if (isAppeal(authentication?.reason)) {
    data = {
      ...data,
      animationType: 'intro',
      cardsType: 'simple',
    };
  }

  const { cardsType, response, title: _responseTitle, customAnimation } = data;
  const responseProps = responses[response || 'impossible'] || {};
  const responseTitle = _responseTitle || responseProps.title;
  const Animation = responseProps.animation[data.animationType || 'complete'];
  const isBranded = (authenticatorConf || {}).name !== 'default';
  const authenticationCompleteHideCards = get(
    authenticatorConf,
    'authenticationConf.completeHideCards',
  );
  const authenticationCompleteTemporization = get(
    authenticatorConf,
    'authenticationConf.completeTemporization',
  );

  const showCards =
    (authentication?.service === 'connect' && authentication?.response === 'impossible') ||
    !(
      (isNil(authenticationCompleteHideCards) &&
        authentication?.service === 'connect' &&
        authentication?.response !== 'impossible') ||
      authenticationCompleteHideCards
    );

  const redirectTemporization =
    authentication?.service === 'connect' && authentication?.response === 'impossible'
      ? null
      : isNil(authenticationCompleteTemporization) &&
        authentication?.service === 'connect' &&
        authentication?.response !== 'impossible'
      ? 2000
      : authenticationCompleteTemporization;

  const goToConnectConsole = () => history.push(`/connect/${authentication.sessionId}`);

  const buttonConnectConsole = authentication?.response === 'impossible' &&
    authentication?.service === 'connect' && <ButtonHome sessionId={authentication.sessionId} />;

  const handleLoaderAnimationEnd = () => {
    setShowResponse(true);
  };

  const handleAnimationEnd = () => {
    setTimeout(
      () => {
        setShowProgressBar(true);
        setShowResponseCarousel(showCards);
      },
      isBranded ? 2000 : 1000,
    );
  };

  const propsBag = {
    id: authenticationId,
    service: 'protect',
    certilogoCode: get(data, 'certilogoCode'),
    primaryBrand: get(data, 'primaryBrand.name'),
    tmBrand: get(data, 'tmBrand.name'),
    authentication,
    logger,
  };

  return (
    <Wrapper colorType={colorType}>
      <Portal>
        {responseDimensions.x && (
          <Animation
            onLoaderAnimationEnd={handleLoaderAnimationEnd}
            onAnimationEnd={handleAnimationEnd}
            assistant={{
              start: {
                x: responseDimensions.x,
                y: responseDimensions.y - 300,
              },
              end: {
                x: responseDimensions.x + 50,
                y: responseDimensions.y - responseTranslationHeight,
              },
              ...responseProps.assistant,
            }}
            explosion={{
              ...responseProps.explosion,
            }}
            hideExplosionOnEnd={isBranded}
            colorType={colorType}
            customAnimation={customAnimation}
          />
        )}
      </Portal>
      <ResponseWrapper>
        <Div ref={responseWrapperRef}>
          <Response colorType={colorType} ref={responseRef} show={showResponse} style={springProps}>
            {responseProps && (
              <FormattedMessageMarkdown
                colorType={colorType}
                id={responseTitle}
                defaultMessage={responseTitle}
              />
            )}
            {redirectTemporization && showProgressBar && (
              <ProgressBarTimer
                colorType={colorType}
                interval={redirectTemporization}
                onComplete={goToConnectConsole}
                strokeColor={color({ theme, colorType })}
                trailColor={transparentize(0.8, color({ theme, colorType }))}
                marginTop="m"
              />
            )}
          </Response>
        </Div>
        {showCards && (
          <animated.div style={carouselProps}>
            <ResponseCarouselWrapper>
              <ResponseCarousel
                colorType={colorType}
                data={data}
                propsBag={propsBag}
                cardsType={cardsType}
                height={window.innerHeight - 60 - 25 - responseHeight - 20 - 20 - poweredByHeight}
              />
              {buttonConnectConsole}
            </ResponseCarouselWrapper>
          </animated.div>
        )}
      </ResponseWrapper>
    </Wrapper>
  );
}

Complete.propTypes = {
  colorType: PropTypes.string,
};

export default withColorType(Complete);
